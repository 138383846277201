import React, { Component } from "react";
import { Menu, Dropdown } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// const menu = (
//   // <Menu>
//   //   <Menu.Item>
//   //     <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
//   //       1st menu item
//   //     </a>
//   //   </Menu.Item>
//   //   <Menu.Item >
//   //     <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
//   //       2nd menu item (disabled)
//   //     </a>
//   //   </Menu.Item>
//   // </Menu>
// );
const LeftMenu = (props) => {
  return (
    // <Menu mode="horizontal">
    <div className="nav-options">
      <a
        className="ant-dropdown-link cursor-pointer Biotif-Regular"
        onClick={() => props.history.push("/style")}
      >
        STYLE ↓
      </a>

      <a
        className="ant-dropdown-link cursor-pointer Biotif-Regular"
        onClick={() => props.history.push("/self")}
      >
        SELF ↓
      </a>

      <a
        className="ant-dropdown-link cursor-pointer Biotif-Regular"
        onClick={() => props.history.push("/culture")}
      >
        CULTURE ↓
      </a>

      <a
        className="ant-dropdown-link cursor-pointer Biotif-Regular"
        onClick={() => props.history.push("/community")}
      >
        COMMUNITY ↓
      </a>
      <a
        className="ant-dropdown-link cursor-pointer Biotif-Regular"
        onClick={() => props.history.push("/news-letter")}
      >
        SUBSCRIBE ↓
      </a>
    </div>
  );
};
export default withRouter(LeftMenu);
