import React, { useEffect, useMemo, useState } from "react";
import "../style.css";
import EstdFullFormat1Card1 from "./EstdFullFormat1Card1";
import EstdFullFormat1card2 from "./EstdFullFormat1card2";
import ReadMoreSection from "../ReadMoreSection";
import { Helmet } from "react-helmet";
import Twitter from "../../../assets/images/twlogopink.svg";
import Instagram from "../../../assets/images/lilogopink.svg";
import Facebook from "../../../assets/images/fblogopink.svg";
import {
  imgBaseURL,
  maxContentAd,
  horizontalSmallAdHeight,
} from "../../../helper/constants";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import GoogleAdvertisement from "../../Advertise/GoogleAdvertisement";
import { Link } from "react-router-dom";
import moment from "moment";
import { generateSchemaData } from "../../../helper/schemaData";

export default function EstdFullFormat1(props) {
  const data = props.data;
  const cardType = [];
  const cards = data["story"]["cards"];
  console.log("cards", cards);
  const [displayFlag, setDisplayFlag] = useState(false);
  const [cardtype, setcardtype] = useState([]);
  var tempCardOneFlag;
  useEffect(() => {
    console.log("inside useeffect");
    window.scroll(0, 0);
    tempCardOneFlag = true;
    let tempcont = 0;
    cards.map((card, index) => {
      let typeNotSelected = true;
      if (Object.keys(card.metadata.attributes).length >= 1) {
        if ("alternateheroimage" in card.metadata.attributes) {
          if (card.metadata.attributes.alternateheroimage[0] === "yes") {
            console.log("hero image");
          }
        }
        if ("contentimgsidebyside" in card.metadata.attributes) {
          if (card.metadata.attributes.contentimgsidebyside[0] === "yes") {
            console.log("side by side");
            cardType[index] = "card2";
          } else {
            card["story-elements"].map((element) => {
              if (tempcont === 0) {
                tempcont = 1;
                cardType[index] = "card1";
                typeNotSelected = false;
              } else if (
                element.type === "image" &&
                element.subtype === null &&
                typeNotSelected
              ) {
                cardType[index] = "card2";
                typeNotSelected = false;
              } else if (
                element.type === "composite" &&
                element.subtype === "image-gallery" &&
                typeNotSelected
              ) {
                cardType[index] = "card2";
                typeNotSelected = false;
              } else if (
                element.type === "text" &&
                element.subtype === "also-read"
              ) {
                cardType[index] = "also-read";
                typeNotSelected = false;
              } else {
                console.log("inside else");
                cardType[index] = "card2";
              }
            });
          }
        }
        if ("cardbackgroungcolour" in card.metadata.attributes) {
          if (card.metadata.attributes.cardbackgroungcolour.length >= 1) {
            console.log(
              "bgcloor",
              card.metadata.attributes.cardbackgroungcolour[0]
            );
            // cardBgColor[index]= card.metadata.attributes.cardbackgroungcolour[0]
            // setCardBgColor([...cardBgColor])
          }
        }
      } else {
        console.log("story ele", card["story-elements"], card);
        card["story-elements"].map((element) => {
          console.log("flag value", typeNotSelected);
          if (tempcont === 0) {
            tempcont = 1;
            cardType[index] = "card1";
            typeNotSelected = false;
          } else if (
            element.type === "image" &&
            element.subtype === null &&
            typeNotSelected
          ) {
            cardType[index] = "card2";
            typeNotSelected = false;
          } else if (
            element.type === "composite" &&
            element.subtype === "image-gallery" &&
            typeNotSelected
          ) {
            cardType[index] = "card2";
            typeNotSelected = false;
          } else if (
            element.type === "text" &&
            element.subtype === "also-read"
          ) {
            cardType[index] = "also-read";
            typeNotSelected = false;
          } else if (typeNotSelected) {
            console.log("inside else");
            cardType[index] = "card2";
          }
        });
      }
    });
    console.log("cardtype", cardType);
    setcardtype(cardType);
    setDisplayFlag(true);
  }, []);

  console.log("a4 inside", data);

  const heroData = {
    img: "https://gumlet.assettype.com/" + data["story"]["hero-image-s3-key"],
    imgMeta: data["story"]["hero-image-metadata"],
    imgTitle: data["story"]["hero-image-caption"],
    cat: data["story"]["sections"][0]["name"].toUpperCase(),
    title: data["story"]["headline"],
    auth: data["story"]["author-name"],
    imgCap: data["story"]["hero-image-attribution"],
    authId: data["story"]["author-id"],
  };
  if ("home" in data["story"]["alternative"]) {
    if ("default" in data["story"]["alternative"]["home"]) {
      console.log("alernative", data["story"]["alternative"]["home"]);
      if ("hero-image" in data["story"]["alternative"]["home"]["default"]) {
        if (
          data["story"]["alternative"]["home"]["default"]["hero-image"] != null
        ) {
          if (
            "hero-image-s3-key" in
            data["story"]["alternative"]["home"]["default"]["hero-image"]
          ) {
            console.log(
              "image changed",
              data["story"]["alternative"]["home"]["default"]["hero-image"][
                "hero-image-s3-key"
              ]
            );
            heroData.img =
              imgBaseURL +
              data["story"]["alternative"]["home"]["default"]["hero-image"][
                "hero-image-s3-key"
              ];
            heroData.imgMeta =
              data["story"]["alternative"]["home"]["default"]["hero-image"][
                "hero-image-metadata"
              ];
          }
        }
      }
    }
  }
  var isImgLandscape = false;
  var imgWidth = "50%";
  if (heroData.imgMeta.width > heroData.imgMeta.height) {
    isImgLandscape = true;
    imgWidth = "60%";
  }
  const metaDataStory = {
    title: heroData.title,
    description: heroData.title,
    keywords: "The Established",
  };
  if ("seo" in data["story"]) {
    console.log("in seo");
    if ("meta-title" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-title"].length > 1) {
        metaDataStory.title = data["story"]["seo"]["meta-title"];
        console.log("in seo meta");
      } else {
        metaDataStory.title = heroData.title;
      }
    }
    if ("meta-description" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-description"].length > 1) {
        metaDataStory.description = data["story"]["seo"]["meta-description"];
      } else {
        metaDataStory.title = heroData.title;
        console.log("else in seo meta");
      }
    }
    if ("meta-keywords" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-keywords"].length > 1) {
        metaDataStory.keywords =
          data["story"]["seo"]["meta-keywords"].toString();
      }
    }
  } else {
    metaDataStory.title = heroData.title;
    metaDataStory.description = heroData.title;
  }

  const getSchemaData = useMemo(() => {
    return generateSchemaData(props?.data, metaDataStory);
  }, [props.data]);

  let adCounter = 0;

  return (
    <div>
      <Helmet defer={false}>
        <title>{metaDataStory.title}</title>
        <meta
          name="description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />

        <meta
          property="og:title"
          content={metaDataStory.title}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />
        <meta
          property="og:image"
          content={heroData.img}
          data-react-helmet="true"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          name="twitter:card"
          content="summary_large_image"
          data-react-helmet="true"
        />

        <meta
          property="twitter:url"
          content={window.location.href}
          data-react-helmet="true"
        />
        <meta
          property="twitter:title"
          content={metaDataStory.title}
          data-react-helmet="true"
        />
        <meta
          property="twitter:description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />
        <meta
          property="twitter:image"
          content={heroData.img}
          data-react-helmet="true"
        />
        <link
          rel="canonical"
          href={
            data["story"]["canonical-url"]
              ? data["story"]["canonical-url"]
              : window.location.href
          }
          data-react-helmet="true"
        />
        <meta name="author" content={heroData.auth} data-react-helmet="true" />
        <meta name="keywords" content={metaDataStory.keywords} />
      </Helmet>
      {/* Google Tag Manager (gtag.js) */}
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16472986144"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16472986144');
      `}
        </script>
        {props?.data && (
          <script type="application/ld+json">
            {JSON.stringify(getSchemaData)}
          </script>
        )}
      </Helmet>
      {/* <Header /> */}
      <div className="a4-header">
        {/* <div>
            <div
            className="text-left"
            dangerouslySetInnerHTML={{ __html: abc }}
          />
            </div> */}
        <div className="a4-header-img" style={{ width: imgWidth }}>
          <img src={heroData.img} alt="" />
        </div>
        <div className="a4-header-con">
          <div className="Biotif-Book a4-header-cat ">{heroData.cat}</div>
          <h1 className="HeldaneDisplay-Regular a4-header-title">
            {heroData.title}
          </h1>
          <Link
            to={{
              pathname: `/author/${heroData.authId}/${heroData.auth
                ?.replace(/ /g, "-")
                .toLowerCase()}`,
              state: { story: data, authId: heroData.authId },
            }}
          >
            <div className="Biotif-Book a4-header-auth">By {heroData.auth}</div>
          </Link>
          <div className="a5-p1-h1-date Biotif-Book mtp-3">
            {moment(data["story"]["published-at"]).format("DD MMMM, YYYY")}
          </div>
          <div></div>
          <div className="d-flex eff1-social mtp-3 mbp-3">
            <FacebookShareButton
              className="cursor-pointer"
              url={window.location.href}
              quote={heroData.title}
            >
              <img
                className="eff1-social-images cursor-pointer"
                src={Facebook}
                alt="Facebook"
              ></img>
            </FacebookShareButton>
            <LinkedinShareButton
              className="cursor-pointer"
              title={heroData.title}
              url={window.location.href}
              description={metaDataStory.description}
              source={window.location.href}
            >
              <img
                className="eff1-social-images cursor-pointer"
                src={Instagram}
                alt="Instagram"
              ></img>
            </LinkedinShareButton>
            {/* <img className='social-images' src = {Tiktok} alt='Tiktok'></img> */}
            <TwitterShareButton
              className="cursor-pointer"
              title={heroData.title}
              url={window.location.href}
            >
              <img
                className="eff1-social-images cursor-pointer"
                src={Twitter}
                alt="Twitter"
              ></img>
            </TwitterShareButton>
          </div>
        </div>
      </div>
      <div
        className="a4-header-img-title"
        dangerouslySetInnerHTML={{ __html: heroData.imgTitle }}
      ></div>
      {displayFlag &&
        cardtype.map((card, index) =>
          card === "card1" ? (
            <>
              <EstdFullFormat1Card1 cards={cards[index]} />
              {/* {(!(cardtype[index + 1] == 'also-read') && adCounter < maxContentAd) &&
                    <>
                        <div className='d-none'>{adCounter++}</div>
                        <div className="web-view-ad-img web-view-ad-img-horizontal-small">
                            <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal}
                                styleHeight={horizontalSmallAdHeight} />
                        </div>
                        <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
                            <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
                        </div>
                    </>
                    } */}
            </>
          ) : card === "card2" ? (
            <>
              <EstdFullFormat1card2 cards={cards[index]} />
              {/* {!(cardtype[index + 1] == "also-read") &&
                adCounter < maxContentAd && (
                  <>
                    <div className="d-none">{adCounter++}</div>
                    <div className="web-view-ad-img web-view-ad-img-horizontal-small">
                      <GoogleAdvertisement
                        dataAdSlot={
                          process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal
                        }
                        styleHeight={horizontalSmallAdHeight}
                      />
                    </div>
                    <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
                      <GoogleAdvertisement
                        dataAdSlot={
                          process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square
                        }
                      />
                    </div>
                  </>
                )} */}
            </>
          ) : (
            ""
          )
        )}

      {displayFlag &&
        cardtype.map((card, index) =>
          card === "also-read" ? (
            <>
              <ReadMoreSection storyElement={cards[index]["story-elements"]} />
              {/* <div className="web-view-ad-img web-view-ad-img-horizontal-regular">
                <GoogleAdvertisement
                  dataAdSlot={
                    process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal
                  }
                />
              </div>
              <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
                <GoogleAdvertisement
                  dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square}
                />
              </div> */}
            </>
          ) : (
            ""
          )
        )}
      {/* <Footer /> */}
    </div>
  );
}
