import React from "react";
import Twitter from "../../../assets/images/twlogopink.svg";
import Instagram from "../../../assets/images/lilogopink.svg";
import Facebook from "../../../assets/images/fblogopink.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import moment from "moment";

const EstdFullFormatDynamicCard01 = (props) => {
  const { cards, metaDataStory, heroData } = props;
  const storyElement = cards["story-elements"];
  console.log("story ele", storyElement);
  if (storyElement) {
    return (
      <div className="ptp-5 pbp-5">
        <div className="row ma-0 row-data">
          {storyElement.map((element) =>
            element.type === "text" ? (
              <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-0">
                <div className="ffd-social-ic dfestdate">
                  <div className="d-flex main-social-icon">
                    <FacebookShareButton
                      className="cursor-pointer"
                      url={window.location.href}
                      quote={heroData.title}
                    >
                      <img
                        className="social-images"
                        src={Facebook}
                        alt="Facebook"
                      ></img>
                    </FacebookShareButton>
                    <LinkedinShareButton
                      className="cursor-pointer"
                      title={heroData.title}
                      url={window.location.href}
                      description={metaDataStory.description}
                      source={window.location.href}
                    >
                      <img
                        className="social-images"
                        src={Instagram}
                        alt="Instagram"
                      ></img>
                    </LinkedinShareButton>
                    <TwitterShareButton
                      className="cursor-pointer"
                      title={heroData.title}
                      url={window.location.href}
                    >
                      <img
                        className="social-images"
                        src={Twitter}
                        alt="Twitter"
                      ></img>
                    </TwitterShareButton>
                  </div>
                  <div className="Biotif-Regular a5-p1-h1-date">
                    {moment(props?.publishedDate).format("DD MMMM, YYYY")}
                  </div>
                </div>
                <div
                  className="description-head"
                  dangerouslySetInnerHTML={{ __html: element.text }}
                ></div>
              </div>
            ) : element.type === "image" ? (
              <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-0">
                <div className="ffd-b-img">
                  <img
                    width="100%"
                    src={`https://gumlet.assettype.com/${element["image-s3-key"]}`}
                    alt="fashion"
                  ></img>
                  <div
                    className="HeldaneDisplay-Regular image-text"
                    dangerouslySetInnerHTML={{ __html: element["title"] }}
                  ></div>
                </div>
              </div>
            ) : (
              ""
            )
          )}

          {/* {
                    storyElement.map((element)=>(
                        element.type==='image'?<div className='col-lg-6 col-xl-6 col-md-12 col-sm-12'><div>
                            <img width="100%" src = {`https://gumlet.assettype.com/${element['image-s3-key']}`} alt='fashion'></img>
                            <div className='HeldaneDisplay-Regular image-text' dangerouslySetInnerHTML={{ __html: element['title']}}></div>
                        </div></div>:""
                        ))
                    } */}
        </div>
      </div>
    );
  } else return <div></div>;
};

export default EstdFullFormatDynamicCard01;
