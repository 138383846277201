import React, { useEffect, useMemo, useState } from "react";
import "../style.css";
import Header from "../../../components/common/Header2/Header";
import Footer from "../../../components/common/Footer/Footer";
import Twitter from "../../../assets/images/twlogopink.svg";
import Instagram from "../../../assets/images/lilogopink.svg";
import Facebook from "../../../assets/images/fblogopink.svg";
import EstdFullFormat2Card1 from "./EstdFullFormat2Card1";
import EstdFullFormat2Card2 from "./EstdFullFormat2Card2";
import EstdFullFormat2Card3 from "./EstdFullFormat2Card3";
import ReadMoreSection from "../ReadMoreSection";
import {
  imgBaseURL,
  maxContentAd,
  horizontalSmallAdHeight,
} from "../../../helper/constants";
import moment from "moment";
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import GoogleAdvertisement from "../../Advertise/GoogleAdvertisement";
import { Link } from "react-router-dom";
import { generateSchemaData } from "../../../helper/schemaData";

export default function EstdFullFormat2(props) {
  const data = props.data;
  const cardType = [];
  const cards = data["story"]["cards"];
  const [cardBgColor, setCardBgColor] = useState([]);
  console.log("cards", cards);
  const [displayFlag, setDisplayFlag] = useState(false);
  const [cardtype, setcardtype] = useState([]);

  useEffect(() => {
    console.log("inside use");
    window.scroll(0, 0);

    let tempcont = 0;
    cards.map((card, index) => {
      let typeNotSelected = true;
      console.log("alternateheroimage" in card.metadata.attributes);
      if (Object.keys(card.metadata.attributes).length >= 1) {
        if ("alternateheroimage" in card.metadata.attributes) {
          if (card.metadata.attributes.alternateheroimage[0] === "yes") {
            console.log("hero image", index);
          } else {
            cardType[index] = "card2";
          }
        }
        if ("contentimgsidebyside" in card.metadata.attributes) {
          if (card.metadata.attributes.contentimgsidebyside[0] === "yes") {
            console.log("SIDE BY SIDE", index);
            cardType[index] = "card3";
          }
        }
        if ("cardbackgroungcolour" in card.metadata.attributes) {
          if (card.metadata.attributes.cardbackgroungcolour.length >= 1) {
            console.log(
              "bgcloor",
              card.metadata.attributes.cardbackgroungcolour[0]
            );
            cardBgColor[index] =
              card.metadata.attributes.cardbackgroungcolour[0];
            setCardBgColor([...cardBgColor]);
          } else {
            cardType[index] = "card2";
          }
        }
      } else {
        card["story-elements"].map((element) => {
          if (tempcont === 0 && typeNotSelected) {
            tempcont = 1;
            cardType[index] = "card1";
            typeNotSelected = false;
          } else if (
            element.type === "image" &&
            element.subtype === null &&
            typeNotSelected
          ) {
            cardType[index] = "card2";
          } else if (
            element.type === "text" &&
            element.subtype === "also-read" &&
            typeNotSelected
          ) {
            cardType[index] = "also-read";
          } else if (typeNotSelected) {
            cardType[index] = "card2";
          }
        });
      }
    });
    console.log("cardtype", cardType);
    setcardtype(cardType);
    setDisplayFlag(true);
  }, []);

  console.log("a5 inside", data);
  const heroData = {
    img: "https://gumlet.assettype.com/" + data["story"]["hero-image-s3-key"],
    imgTitle: data["story"]["hero-image-caption"],
    cat: data["story"]["sections"][0]["name"].toUpperCase(),
    title: data["story"]["headline"],
    auth: data["story"]["author-name"],
    authId: data["story"]["author-id"],
  };
  if ("home" in data["story"]["alternative"]) {
    if ("default" in data["story"]["alternative"]["home"]) {
      console.log("alernative", data["story"]["alternative"]["home"]);
      if ("hero-image" in data["story"]["alternative"]["home"]["default"]) {
        if (
          data["story"]["alternative"]["home"]["default"]["hero-image"] != null
        ) {
          if (
            "hero-image-s3-key" in
            data["story"]["alternative"]["home"]["default"]["hero-image"]
          ) {
            console.log(
              data["story"]["alternative"]["home"]["default"]["hero-image"][
                "hero-image-s3-key"
              ]
            );
            heroData.img =
              imgBaseURL +
              data["story"]["alternative"]["home"]["default"]["hero-image"][
                "hero-image-s3-key"
              ];
          }
        }
      }
    }
  }
  const metaDataStory = {
    title: heroData.title,
    description: heroData.title,
    keywords: "The Established",
  };
  if ("seo" in data["story"]) {
    console.log("in seo");
    if ("meta-title" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-title"].length > 1) {
        metaDataStory.title = data["story"]["seo"]["meta-title"];
        console.log("in seo meta");
      } else {
        metaDataStory.title = heroData.title;
      }
    }
    if ("meta-description" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-description"].length > 1) {
        metaDataStory.description = data["story"]["seo"]["meta-description"];
      } else {
        metaDataStory.title = heroData.title;
        console.log("else in seo meta");
      }
    }
    if ("meta-keywords" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-keywords"].length > 1) {
        metaDataStory.keywords =
          data["story"]["seo"]["meta-keywords"].toString();
      }
    }
  } else {
    metaDataStory.title = heroData.title;
    metaDataStory.description = heroData.title;
  }

  let adCounter = 0;

  let increaseAdCounter = () => {
    adCounter++;
  };

  //#region - cardtype.map functions will takes one seconds to iterate. Reason - It'll wait for adCounter data to be updated from the child component.
  const [renderedIndex, setRenderedIndex] = useState(0);
  useEffect(() => {
    if (renderedIndex < cardtype.length - 1) {
      const timer = setTimeout(() => {
        setRenderedIndex((prevIndex) => prevIndex + 1);
      }, 1000); // 1 second delay before rendering next component

      return () => clearTimeout(timer); // Cleanup timer
    }
  }, [renderedIndex, cardtype.length]);
  //#endregion
  const getSchemaData = useMemo(() => {
    return generateSchemaData(props?.data, metaDataStory);
  }, [props.data]);

  return (
    <div>
      <Helmet defer={false}>
        <title>{metaDataStory.title}</title>
        <meta
          name="description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />

        <meta
          property="og:title"
          content={metaDataStory.title}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={heroData.img}
          data-react-helmet="true"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
          data-react-helmet="true"
        />

        <meta
          property="twitter:url"
          content={window.location.href}
          data-react-helmet="true"
        />
        <meta
          property="twitter:title"
          content={metaDataStory.title}
          data-react-helmet="true"
        />
        <meta
          property="twitter:description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />
        <meta
          property="twitter:image"
          content={heroData.img}
          data-react-helmet="true"
        />
        <link
          rel="canonical"
          href={
            data["story"]["canonical-url"]
              ? data["story"]["canonical-url"]
              : window.location.href
          }
          data-react-helmet="true"
        />
        <meta name="author" content={heroData.auth} data-react-helmet="true" />
        <meta name="keywords" content={metaDataStory.keywords} />
      </Helmet>
      {/* Google Tag Manager (gtag.js) */}
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16472986144"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16472986144');
      `}
        </script>
        {props?.data && (
          <script type="application/ld+json">
            {JSON.stringify(getSchemaData)}
          </script>
        )}
      </Helmet>
      {/* <Header /> */}
      <div className="estd-full-format-2-web">
        <div className="a5-1-img">
          <img src={heroData.img} alt="" />
        </div>
        <div className="a5-p1">
          <h1 className="a5-p1-h1 InterstateCompressed-Bold">
            {heroData.title.toUpperCase()}
          </h1>
          <div className="a5-p1-h1-bottom">
            <Link
              to={{
                pathname: `/author/${heroData.authId}/${heroData.auth
                  ?.replace(/ /g, "-")
                  .toLowerCase()}`,
                state: { story: data, authId: heroData.authId },
              }}
            >
              <div className="a5-p1-h1-auth Biotif-Book">
                By {heroData.auth}
              </div>
            </Link>
            <div className="a5-p1-h1-date Biotif-Book">
              {moment(data["story"]["published-at"]).format("DD MMMM, YYYY")}
            </div>
          </div>
        </div>
      </div>
      <div className="estd-full-format-2-mobile">
        <div className="a5-p1">
          <h1 className="a5-p1-h1 InterstateCompressed-Bold">
            {heroData.title.toUpperCase()}
          </h1>
          <div className="a5-p1-h1-bottom">
            <Link
              to={{
                pathname: `/author/${heroData.authId}/${heroData.auth
                  ?.replace(/ /g, "-")
                  .toLowerCase()}`,
                state: { story: data, authId: heroData.authId },
              }}
            >
              <div className="a5-p1-h1-auth Biotif-Book">
                By {heroData.auth}
              </div>
            </Link>
            <div className="a5-p1-h1-date Biotif-Book">
              {moment(data["story"]["published-at"]).format("DD MMMM, YYYY")}
            </div>
          </div>
        </div>
        <div className="a5-1-img">
          <img src={heroData.img} alt="" />
        </div>
      </div>
      <div className="a5-p1-h1-bottom   mtp-3 mbp-3">
        <div className="d-flex eff1-social">
          <FacebookShareButton
            className="eff1-social-images cursor-pointer"
            url={window.location.href}
            quote={heroData.title}
          >
            <img
              className="eff1-social-images cursor-pointer"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/theestablishedcom",
                  "_blank"
                )
              }
              src={Facebook}
              alt="Facebook"
            ></img>
          </FacebookShareButton>
          <LinkedinShareButton
            className="cursor-pointer"
            title={heroData.title}
            url={window.location.href}
            description={metaDataStory.description}
            source={window.location.href}
          >
            <img
              className="eff1-social-images cursor-pointer"
              src={Instagram}
              alt="Instagram"
            ></img>
          </LinkedinShareButton>
          <TwitterShareButton
            className="eff1-social-images cursor-pointer"
            title={heroData.title}
            url={window.location.href}
          >
            <img
              className="eff1-social-images cursor-pointer"
              src={Twitter}
              alt="Twitter"
            ></img>
          </TwitterShareButton>
        </div>
      </div>
      {displayFlag &&
        cardtype.slice(0, renderedIndex + 1).map((card, index) =>
          card === "card1" ? (
            <>
              <EstdFullFormat2Card1 cards={cards[index]} />
              {/* {(!(cardtype[index + 1] == 'also-read') && adCounter < maxContentAd) &&
                    <>
                        <div className='d-none'>{adCounter++}</div>
                        <div className="web-view-ad-img web-view-ad-img-horizontal-small">
                            <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal}
                                styleHeight={horizontalSmallAdHeight} />
                        </div>
                        <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
                            <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
                        </div>
                    </>
                    } */}
            </>
          ) : card === "card2" ? (
            <>
              <EstdFullFormat2Card2
                cards={cards[index]}
                isLastCardType={cardtype[index + 1] == "also-read"}
                adCounter={adCounter}
                increaseAdCounter={increaseAdCounter}
              />
            </>
          ) : card === "card3" ? (
            <>
              <EstdFullFormat2Card3
                cardBgColor={cardBgColor[index]}
                cards={cards[index]}
              />
            </>
          ) : (
            ""
          )
        )}
      {displayFlag &&
        cardtype.map((card, index) =>
          card === "also-read" ? (
            <>
              <ReadMoreSection storyElement={cards[index]["story-elements"]} />
              {/* <div className="web-view-ad-img web-view-ad-img-horizontal-regular">
                <GoogleAdvertisement
                  dataAdSlot={
                    process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal
                  }
                />
              </div>
              <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
                <GoogleAdvertisement
                  dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square}
                />
              </div> */}
            </>
          ) : (
            ""
          )
        )}
      {/* <Footer /> */}
    </div>
  );
}
