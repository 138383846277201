import React from "react";
import Footer from "../components/common/Footer/Footer";
import Header from "../components/common/Header/Header";
import TickerComp from "../components/Ticker/TickerComp";
import HomeWrapper from "./style";
import Self from "./CommonSelf/Self";
import CommonSection from "./CommonSection/CommonSection";
// import homeAd1 from '../assets/images/R&M Home1.jpg';
// import homeAd2 from '../assets/images/Website Ads-41.jpg';
// import homeAd3 from '../assets/images/TA Home3.gif';
// import homeAd4 from '../assets/images/Website Ads-12.jpg';
// import homeAdSq1 from '../assets/images/R&M Sq Home1.jpg';
// import homeAdSq2 from '../assets/images/Website Ads_square-32.jpg';
// import homeAdSq3 from '../assets/images/TA Sq.gif';
// import homeAdSq4 from '../assets/images/Website Ads_square-27.jpg'; test
import {
  homeAd1,
  homeAd2,
  homeAd3,
  homeAd4,
  homeAdSq1,
  homeAdSq2,
  homeAdSq3,
  homeAdSq4,
} from "../helper/constants";
import StyleSection from "./StyleSection/StyleSection";
import Community from "./CommonCommunity/Community";
import CultureSection from "./CultureSection/CultureSection";
import { useEffect, useState } from "react";
import SpotlightNew from "./SpotlightNew/SpotlightNew";
import Advertisement from "./Advertise/Advertisement";
// import GoogleAdvertisement from "./Advertise/GoogleAdvertisement";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import Header2 from "../components/common/Header2/Header";
import SubscribeSticky from "../components/common/SubscribeSticky/SubscribeSticky";
import { getOrgSchema } from "../helper/schemaData";

function Home() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const commonHomeAr = [];
  const PhoneView = useMediaQuery({ minWidth: 300, maxWidth: 480 });
  const [commonSectionState, setCommonSectionState] = useState([]);
  const [combinedState1, setCombinedState1] = useState({
    style: [],
    "breaking-news": [],
  });
  const [combinedState2, setCombinedState2] = useState({
    self: [],
    culture: [],
    community: [],
    spotlight: [],
  });
  const [shouldspin, setshouldspin] = useState(true);

  // const allApiCall = async (displaySeq) => {
  //     console.log('all call', displaySeq);
  //     Object.entries(displaySeq).map((k) => {
  //         const secondUrl = `/api/v1/collections/${k[1]}`;
  //         console.log('k value',k);
  //         axios.get(secondUrl).then((r) => {
  //             const tempArr = []
  //             r.data.items.map(a => {
  //                 tempArr.push(a);
  //             })
  //             console.log(k[0], 'array', tempArr);
  //             const arr = combinedState;
  //             arr[k[0]] = tempArr;
  //             setCombinedState({ ...arr });
  //         })
  //     })
  //     console.log("hello final", combinedState )
  // }

  useEffect(() => {
    axios.get("/api/v1/collections/home").then((r) => {
      const displaySeq = {};
      r.data.items.map((a) => {
        if (a.type === "collection") {
          displaySeq[a.name.toLowerCase()] = a.slug;
        } else if (a.type === "story") {
          commonHomeAr.push(a);
        }
      });
      setCommonSectionState(commonHomeAr);
      const tempObj1 = {};
      const tempObj2 = {};
      Object.values(displaySeq).map((a) => {
        if (a === "style" || a === "breaking-news") {
          tempObj1[a] = {
            _type: "collection",
            slug: a,
            "item-type": "stories",
          };
        } else {
          tempObj2[a] = {
            _type: "collection",
            slug: a,
            "item-type": "stories",
          };
        }
      });
      const bodyAPIBulk1 = {
        requests: tempObj1,
      };
      const bodyAPIBulk2 = {
        requests: tempObj2,
      };
      const bulkBody = JSON.stringify(bodyAPIBulk1);
      // allApiCall(displaySeq);
      // axios.post('https://theestablished.qtstage.io/api/v1/bulk-request', bulkBody).then(r => console.log('bulk success', r)).catch(e => console.log('bulk e', e));
      axios.post("/api/v1/bulk-request1", bodyAPIBulk1).then((resp) => {
        const arr = combinedState1;
        Object.entries(resp.data.results).map((col) => {
          arr[col[0]] = col[1].items;
        });
        setCombinedState1({ ...arr });
      });
      axios.post("/api/v1/bulk-request2", bodyAPIBulk2).then((resp) => {
        const arr2 = combinedState2;
        Object.entries(resp.data.results).map((col) => {
          arr2[col[0]] = col[1].items;
        });
        setCombinedState2({ ...arr2 });
      });
    });
    // console.log(homeResp);
  }, []);

  return (
    <HomeWrapper>
      <Helmet defer={false}>
        <title>
          The Established: Style, Self, Culture, Community - Voice of a new
          generation
        </title>
        <meta
          name="description"
          content="The Established is a new digital platform at the forefront of a modern landscape, shaped by a community of conscious, authentic and very well-informed, progressive consumers."
        />
      </Helmet>
      {/* Google Tag Manager (gtag.js) */}
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16472986144"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16472986144');
      `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(getOrgSchema())}
        </script>
      </Helmet>
      {PhoneView ? (
        <Header2 />
      ) : (
        <Header resp={combinedState1["breaking-news"]} />
      )}
      {commonSectionState.length >= 1 ? (
        <CommonSection resp={commonSectionState} shouldspin={shouldspin} />
      ) : (
        ""
      )}
      {combinedState1.style.length >= 1 ? (
        <div>
          <TickerComp resp={combinedState1["breaking-news"]} />
          <StyleSection resp={combinedState1.style} shouldspin={shouldspin} />
        </div>
      ) : (
        ""
      )}
      {combinedState2.self.length >= 1 ? (
        <div>
          <div className="web-view-ad-img">
            {/* <Advertisement img={homeAd1} linkStatus='external' url={'https://www.randmluxury.com/'} /> */}
            <Advertisement
              img={homeAd1.img}
              linkStatus={homeAd1.linkStatus}
              url={homeAd1.url}
            />
            {/* <  dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} /> */}
          </div>
          <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
            <Advertisement
              img={homeAdSq1.img}
              linkStatus={homeAdSq1.linkStatus}
              url={homeAdSq1.url}
            />
            {/* <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} /> */}
          </div>
          <Self resp={combinedState2.self} shouldspin={shouldspin} />
          <div className="web-view-ad-img">
            <Advertisement
              img={homeAd3.img}
              linkStatus={homeAd3.linkStatus}
              url={homeAd3.url}
            />
            {/* <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} /> */}
          </div>
          <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
            <Advertisement
              img={homeAdSq3.img}
              linkStatus={homeAdSq3.linkStatus}
              url={homeAdSq3.url}
            />
            {/* <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} /> */}
          </div>
          <CultureSection resp={combinedState2.culture} />
          <div className="web-view-ad-img">
            <Advertisement
              img={homeAd1.img}
              linkStatus={homeAd1.linkStatus}
              url={homeAd1.url}
            />
            {/* <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} /> */}
          </div>
          <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
            <Advertisement
              img={homeAdSq1.img}
              linkStatus={homeAdSq1.linkStatus}
              url={homeAdSq1.url}
            />
            {/* <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} /> */}
          </div>
          <Community resp={combinedState2.community} />
          <div className="web-view-ad-img">
            <Advertisement
              img={homeAd3.img}
              linkStatus={homeAd3.linkStatus}
              url={homeAd3.url}
            />
            {/* <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} /> */}
          </div>
          <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
            <Advertisement
              img={homeAdSq3.img}
              linkStatus={homeAdSq3.linkStatus}
              url={homeAdSq3.url}
            />
            {/* <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} /> */}
          </div>
          <SpotlightNew resp={combinedState2.spotlight} />
        </div>
      ) : (
        <div style={{ height: "80vh" }}></div>
      )}

      {/* <Subscribe /> */}
      {/* <Mailchimp/> */}
      <SubscribeSticky />
      <Footer />
    </HomeWrapper>
  );
}
export default Home;
