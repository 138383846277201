import React from 'react';
import './style.css'
import { withRouter } from 'react-router-dom';

function Hamculture(props) {
    return (

        <div className='ham-2'>
            <div className='ham-style-right'>
                <div className='ham-center'>
                    <div className='ham-style-right-opt Biotif-Bold' onClick={() => {
                        props.history.push('/culture', { key: '1' })
                        props.onClose();
                    }}>
                        → AUTO
                    </div>
                    <div className='ham-style-right-opt Biotif-Bold' onClick={() => {
                        props.history.push('/culture', { key: '2' })
                        props.onClose();
                    }}>
                        → ENTERTAINMENT
                    </div>
                    <div className='ham-style-right-opt Biotif-Bold' onClick={() => {
                        props.history.push('/culture', { key: '3' })
                        props.onClose();
                    }}>
                        → GAMING & TECH
                    </div>
                    <div className='ham-style-right-opt Biotif-Bold' onClick={() => {
                        props.history.push('/culture', { key: '4' })
                        props.onClose();
                    }}>
                        → LIVING
                    </div>


                </div>
            </div>
        </div>
    )
}
export default withRouter(Hamculture)