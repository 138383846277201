import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import SectionWrapper from "./style";
import Header from "../../components/common/Header2/Header";
import Footer from "../../components/common/Footer/Footer";
import culture from "../../assets/images/title_svg_culture.svg";
import SectionStoryDisplay from "../SectionStoryDisplay/SectionStoryDisplay";
import Api from "../../helper/Api";
import { Helmet } from "react-helmet";
import { categoryID } from "../../helper/constants";
import SubscribeSticky from "../../components/common/SubscribeSticky/SubscribeSticky";

const { TabPane } = Tabs;
const Culture = (props) => {
  const [defaultActiveKey, setDefaultActiveKey] = useState(
    props.location.state && props.location.state.key
      ? props.location.state.key
      : "0"
  );
  const [storyDataToLaod, setStoryDataToLaod] = useState([]);
  const [storyMetaData, setStoryMetaData] = useState({
    from: 0,
    size: 0,
    total: 0,
  });
  const fetchStory = 45;
  useEffect(() => {
    window.scroll(0, 0);
    // Api('GET', '/api/v1/config', {}, {}).then((r) => {
    //     console.log('api config', r);
    // })
    if (defaultActiveKey === "1") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${categoryID.autoID}&limit=47&offset=0`,
        {},
        { sectionId: "38232" }
      ).then((r) => {
        setStoryDataToLaod(r.data.results.stories);
        setStoryMetaData({
          from: 0,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "2") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${categoryID.entertainmentID}&limit=47&offset=0`,
        {},
        { sectionId: "38233" }
      ).then((r) => {
        setStoryDataToLaod(r.data.results.stories);
        setStoryMetaData({
          from: 0,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "3") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${categoryID.gamingID}&limit=47&offset=0`,
        {},
        { sectionId: "38234" }
      ).then((r) => {
        setStoryDataToLaod(r.data.results.stories);
        setStoryMetaData({
          from: 0,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "4") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${categoryID.livingID}&limit=47&offset=0`,
        {},
        { sectionId: "38235" }
      ).then((r) => {
        setStoryDataToLaod(r.data.results.stories);
        setStoryMetaData({
          from: 0,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "0") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${categoryID.cultureID}&limit=47&offset=0`,
        {},
        { sectionId: "38231" }
      ).then((r) => {
        setStoryDataToLaod(r.data.results.stories);
        setStoryMetaData({
          from: 0,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    }
  }, [defaultActiveKey]);
  useEffect(() => {
    setDefaultActiveKey(
      props.location.state && props.location.state.key
        ? props.location.state.key
        : "0"
    );
  }, [props.location.state]);

  const getMoreStories = () => {
    if (defaultActiveKey === "1") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${
          categoryID.autoID
        }&limit=${fetchStory}&offset=${
          storyMetaData.from + storyMetaData.size
        }`,
        {}
      ).then((r) => {
        const temp = r.data.results.stories;
        setStoryDataToLaod([...storyDataToLaod, ...temp]);
        setStoryMetaData({
          from: r.data.results.from,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "2") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${
          categoryID.entertainmentID
        }&limit=${fetchStory}&offset=${
          storyMetaData.from + storyMetaData.size
        }`,
        {}
      ).then((r) => {
        const temp = r.data.results.stories;
        setStoryDataToLaod([...storyDataToLaod, ...temp]);
        setStoryMetaData({
          from: r.data.results.from,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "3") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${
          categoryID.gamingID
        }&limit=${fetchStory}&offset=${
          storyMetaData.from + storyMetaData.size
        }`,
        {}
      ).then((r) => {
        const temp = r.data.results.stories;
        setStoryDataToLaod([...storyDataToLaod, ...temp]);
        setStoryMetaData({
          from: r.data.results.from,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "4") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${
          categoryID.livingID
        }&limit=${fetchStory}&offset=${
          storyMetaData.from + storyMetaData.size
        }`,
        {}
      ).then((r) => {
        const temp = r.data.results.stories;
        setStoryDataToLaod([...storyDataToLaod, ...temp]);
        setStoryMetaData({
          from: r.data.results.from,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "0") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${
          categoryID.cultureID
        }&limit=${fetchStory}&offset=${storyMetaData.from + storyMetaData.size}`
      ).then((r) => {
        const temp = r.data.results.stories;
        setStoryDataToLaod([...storyDataToLaod, ...temp]);
        setStoryMetaData({
          from: r.data.results.from,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    }
  };
  function callback(key) {
    setDefaultActiveKey(key);
  }

  return (
    <SectionWrapper>
      <Helmet defer={false}>
        <title>Latest on Auto, Entertainment, Gaming & Tech & Living</title>
        <meta
          name="description"
          content="Join us for the latest in Tech, Culture, Gaming, Entertainment, Art & Design. News to keep you in the know."
        />
      </Helmet>
       {/* Google Tag Manager (gtag.js) */}
       <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16472986144"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16472986144');
      `}
        </script>
      </Helmet>
      <Header />
      <div className="main-cat-div">
        <Tabs
          className="Biotif-Bold"
          activeKey={defaultActiveKey}
          onChange={callback}
        >
          <div style={{ display: "none" }}>
            <TabPane className="d-none" tab="FASHION" key="0"></TabPane>
          </div>
          <TabPane tab="AUTO" key="1"></TabPane>
          <TabPane tab="ENTERTAINMENT" key="2"></TabPane>
          <TabPane tab="GAMING & TECH" key="3"></TabPane>
          <TabPane tab="LIVING" key="4"></TabPane>
        </Tabs>
      </div>

      <div className="culture-heading InterstateCompressed-Bold mtp-5">
        <img
          src={culture}
          alt="style logo"
          className="cursor-pointer"
          onClick={() => setDefaultActiveKey("0")}
        />
      </div>
      <SectionStoryDisplay stories={storyDataToLaod} cat="culture" />
      {storyMetaData.from + storyMetaData.size < storyMetaData.total ? (
        <div className="d-flex justify-content Biotif-Regular mtp-5 mbp-8 section-read-more">
          <button
            className="read-more-btn"
            onClick={() => {
              getMoreStories();
            }}
          >
            SHOW MORE →
          </button>
        </div>
      ) : (
        <div className="mbp-10"></div>
      )}
      {/* <Subscribe/> */}
      <SubscribeSticky/>
      <Footer />
    </SectionWrapper>
  );
};

export default Culture;
