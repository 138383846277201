import React from 'react';
import { withRouter } from 'react-router-dom';
import './style.css'

 function Hamstyle(props) {
    return (

            <div className='ham-2'>
                <div className='ham-style-right'>
                    <div className='ham-center'>

                    <div className='ham-style-right-opt Biotif-Bold' onClick={()=>{props.history.push('/style',{key:'1'});
                    props.onClose();
                }}>
                    → FASHION
                    </div>
                    <div className='ham-style-right-opt Biotif-Bold' onClick={()=>{props.history.push('/style',{key:'2'})
                    props.onClose();
                }}>
                    → SNEAKERS
                    </div>
                    <div className='ham-style-right-opt Biotif-Bold' onClick={()=>{props.history.push('/style',{key:'3'});
                    props.onClose();
                }}>
                    → WATCHES
                    </div>
                    </div>
                </div>
            </div>
    )
}

export default withRouter(Hamstyle)